@import '@lendoab/aphrodite/dist/tokens';

.container {
    width: initial;
    @media (max-width: map-get($breakpoints, upper-tablet)) {
        width: 100%;
    }
}

.logo {
    aspect-ratio: 2 / 1;
    width: 96px;
}

.text {
    width: 96px;
    white-space: pre-line;
    word-break: break-word;
    text-transform: capitalize;
}
